import React from 'react';
import { CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { ALL_USER_ROLES_NAMES } from '../config/user.config';
import suppliers from '../Suppliers';
import addresses from '../Addresses';
import stores from '../Store';
import storeSupplier from '../StoreSupplier';
import categories from '../Categories';
import tags from '../Tags';
import goods from '../Goods';
import options from '../Options';
import optionGroups from '../OptionGroups';
import users from '../Users';
import userAddresses from '../UserAddresses';
import orders from '../Orders';
import scheduledOrders from '../ScheduledOrders';
import courier from '../Courier';
import variants from '../Variants';
import menu from '../Menu';
import transaction from '../Transaction';
import paymentConfig from '../PaymentConfig';
import discounts from '../Discounts';
import deliverySettings from '../DeliverySettings';
import RESOURCES_NAMES from '../config/resources.config';
import navigation from '../Navigation';
import discountReport from '../DiscountReport';
import courierPayoutPolicy from '../CourierPayoutPolicy';
import marketingCampaigns from '../MarketingCampaign/Campaign';
import promocode from '../PromoCodes';
import marketingCampaignsRun from '../MarketingCampaign/Run';
import Reports from '../Reports';
import userDeleting from '../UserDeleting';
import locale from '../UILocales';
import uiStatics from '../UIStaticPages';
import UITranslations from '../UITranslations';
import companies from '../Companies';
import uiTheme from '../UITheme';
import uiBanners from '../UIBanners';
import uiHtmlSeo from '../UIHtmlSeo';
import uiSettings from '../UISettings';
import UIFooter from '../UIFooter';
import notification from '../Notification';
import notificationLogging from '../Notification-Logging';
import document from '../Document';
import serviceFees from '../ServiceFees';
import { ENABLED_FEATURE_FLAGS } from '../config/enabledFeatureFlags';

// custom routes don't work as intended if they are separated in a component

const renderRoutes = (permissions) => {
  const isAdmin = permissions === ALL_USER_ROLES_NAMES.ADMIN;
  const isSupplierAdmin = permissions === ALL_USER_ROLES_NAMES.SUPPLIER_ADMIN;
  const isSupplierBranch = permissions === ALL_USER_ROLES_NAMES.SUPPLIER_BRANCH;

  const isServiceFeesEnabled = localStorage.getItem('enabledFeatureFlags')?.includes(
    ENABLED_FEATURE_FLAGS.SERVICE_FEES,
  );

  if (isAdmin) {
    return [
      <Resource key="order/today" name="order/today" {...orders} />,
      <Resource key="order/history" name="order/history" {...orders} />,
      <Resource key="order/scheduled" name="order/v1/scheduled" {...orders} />,
      <Resource key="user" name="user" {...users} />,
      <Resource key="useraddress" name="useraddress" {...userAddresses} />,
      <Resource key="tag" name="tag" {...tags} />,
      <Resource key="navigation" name="navigation" {...navigation} />,
      <Resource key="reportsDiscount" name="reportsDiscount" {...discountReport} />,
      <Resource key="supplier" name="supplier" {...suppliers} />,
      <Resource key="address" name="address" {...addresses} />,
      <Resource key="store" name="store" {...stores} />,
      <Resource key="store-supplier" name="store-supplier" {...storeSupplier} />,
      <Resource key="variants" name="variants" {...variants} />,
      <Resource key="category" name="category" {...categories} />,
      <Resource key="goods" name="goods" {...goods} />,
      <Resource key="optionGroup" name="optionGroup" {...optionGroups} />,
      <Resource key="option" name="option" {...options} />,
      <Resource key="discount" name="discount" {...discounts} />,
      <Resource key="menu" name="menu" {...menu} />,
      <Resource key="company" name="company" {...companies} />,
      <Resource
        key="deliverySettings"
        name={RESOURCES_NAMES.DELIVERY_SETTINGS}
        {...deliverySettings}
      />,
      <Resource key="user" name="user" {...users} />,
      <Resource key="useraddress" name="useraddress" {...userAddresses} />,
      <Resource key="courier" name="courier" {...courier} />,
      <Resource key="transaction" name="transaction" {...transaction} />,
      <Resource key="paymentConfig" name="paymentConfig" {...paymentConfig} />,
      <Resource key="policy" name="policy" {...courierPayoutPolicy} />,
      <Resource key="marketing-campaigns" name="marketing-campaigns" {...marketingCampaigns} />,
      <Resource key="promocode" name="promocode" {...promocode} />,
      <Resource
        key="marketing-campaign-runs"
        name="marketing-campaign-runs"
        {...marketingCampaignsRun}
      />,
      <Resource key="user-delete" name="user/deleted" {...userDeleting} />,
      <Resource key="uiLocale" name="ui/locale" {...locale} />,
      <Resource key="uiStatics" name="ui/static" {...uiStatics} />,
      <Resource key="uiTheme" name="ui/theme" {...uiTheme} />,
      <Resource key="uiBanner" name="ui/banner" {...uiBanners} />,
      <Resource key="uiSettings" name="ui/settings" {...uiSettings} />,
      <Resource key="uiHtmlSeo" name="ui/html-seo" {...uiHtmlSeo} />,
      <Resource key="notification" name="notification" {...notification} />,
      <Resource key="notificationLogging" name="notification-logging" {...notificationLogging} />,
      <Resource key="document" name="document" {...document} />,

      isServiceFeesEnabled && <Resource key="service-fees" name="service-fees" {...serviceFees} />,

      <CustomRoutes>
        <Route exact path="/report" element={<Reports />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route exact path="/ui/translation" element={<UITranslations />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route exact path="/ui/footer" element={<UIFooter />} />
      </CustomRoutes>,
    ];
  }

  if (isSupplierAdmin) {
    return [
      <Resource key="order/today" name="order/today" {...orders} />,
      <Resource key="order/history" name="order/history" {...orders} />,
      <Resource key="address" name="address" {...addresses} />,
      <Resource key="category" name="category" {...categories} />,
      <Resource key="goods" name="goods" {...goods} />,
      <Resource key="optionGroup" name="optionGroup" {...optionGroups} />,
      <Resource key="option" name="option" {...options} list={null} />,
      <Resource key="menu" name="menu" {...menu} />,
      // <Resource key="supplier" name="supplier" {...suppliers} />,
      <Resource key="user" name="user" {...users} />,
      // <Resource key="policy" name="policy" {...courierPayoutPolicy} />,
      // <Resource key="marketing-campaigns" name="marketing-campaigns" {...marketingCampaigns} />,
      <Resource key="run" name="run" {...marketingCampaignsRun} />,
      <CustomRoutes>
        <Route exact path="/report" element={<Reports />} />
      </CustomRoutes>,
    ];
  }

  if (isSupplierBranch) {
    return [
      <Resource key="order/today" name="order/today" {...orders} />,
      <Resource key="order/history" name="order/history" {...orders} />,
      <CustomRoutes>
        <Route exact path="/report" element={<Reports />} />
      </CustomRoutes>,
    ];
  }

  return [
    <Resource key="order/today" name="order/today" {...orders} />,
    <Resource key="order/history" name="order/history" {...orders} />,
    <Resource key="order/schedule" name="order/v1/schedule" {...scheduledOrders} />,
    <Resource key="address" name="address" {...addresses} />,
    <Resource key="store" name="store" {...stores} />,
    <Resource key="marketing-campaigns" name="marketing-campaigns" {...marketingCampaigns} />,
    <Resource key="category" name="category" {...categories} />,
    <Resource key="address" name="address" {...addresses} />,
    <Resource key="goods" name="goods" {...goods} />,
    <Resource key="optionGroup" name="optionGroup" {...optionGroups} />,
    <Resource key="option" name="option" {...options} list={null} />,
    <Resource key="supplier" name="supplier" {...suppliers} />,
    <CustomRoutes>
      <Route exact path="/report" element={<Reports />} />
    </CustomRoutes>,
  ];
};

export default renderRoutes;
