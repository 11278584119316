import React from 'react';
import {
  TextInput,
  Filter,
  SelectInput,
  // SelectArrayInput,
  // ReferenceArrayInput,
} from 'react-admin';
import { statuses } from '../../../config/supplier.config';

export const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="translations.value" alwaysOn />
      <SelectInput
        style={{ minWidth: '200px' }}
        label="ra.label.status"
        source="status"
        choices={statuses}
        alwaysOn
        resettable
      />
      {/* Temporary disabled because of supplier admin crashes 401 unauthorized error 18.03.2025 */}
      {/* <ReferenceArrayInput alwaysOn source="tagIds" reference="tag" perPage={999}>
        <SelectArrayInput
          optionText="name"
          label="resources.tag.name"
          sx={{ minWidth: '200px' }}
        />
      </ReferenceArrayInput> */}
    </Filter>
  );
};
