import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import ListDeleteConfirmationButton from '../../../components/ListDeleteConfirmationButton';
import ColorField from '../../../components/ColorField';

const UIThemeList = ({ ...props }) => {
  return (
    <List {...props}>
      <Datagrid rowClick="show" bulkActionButtons={<ListDeleteConfirmationButton />}>
        <TextField label="ra.label.title" source="title" />
        <TextField label="ra.label.phone" source="phone" />
        <TextField label="ra.label.email" source="email" />
        <ColorField
          label="ra.label.primaryColor"
          source="colors.--theme-primary-color"
        />
      </Datagrid>
    </List>
  );
};

export default UIThemeList;
