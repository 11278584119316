import {
  regex,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import React, { useMemo } from 'react';
import randomString from 'crypto-random-string';
import { useWatch } from 'react-hook-form';
import S3FileInput from '../../../../components/S3FileUploader/Input';
import { baseUrl } from '../../../../config/connection';
import FormWithPreview from '../../../../components/FormWithPreview';

const validateRequired = required();

const cssColorRegex =
  /^(#([0-9a-fA-F]{3}){1,2}|(rgba?|hsla?)\((\s*\d+%?\s*,){2}\s*[\d.]+%?\s*(,\s*[\d.]+\s*)?\)|[a-zA-Z]+)$/;
const colorValidation = regex(
  cssColorRegex,
  // eslint-disable-next-line max-len
  'Color should be one of the following formats: #f00, #123456, rgba(255, 0, 0, 0.5), blue, hsla(240, 100%, 50%, 0.7)',
);

export const ColorFieldsList = [
  '--theme-primary-color',
  '--theme-primary-color-light',
  '--theme-primary-dark-color',
  '--theme-secondary-color',
  '--theme-secondary-light-color',
  '--theme-secondary-dark-color',
  '--theme-header-text-color',
  '--theme-header-back-text-color',
  '--theme-header-background-color',
  '--theme-footer-title-text-color',
  '--theme-footer-static-text-color',
  '--theme-footer-top-background-color',
  '--theme-footer-bottom-static-text-color',
  '--theme-footer-bottom-background-color',
];

const FileLabel = ({ children }) => (
  <span style={{ margin: '0 0 15px 0', display: 'block' }}>{children}</span>
);

const UIThemeForm = () => {
  const form = useWatch();

  const previewChanges = useMemo(() => {
    const siteData = {
      ...form,
      logo: form.logo?.[0]?.url,
      logoMobile: form.logoMobile?.[0]?.url,
    };

    return {
      siteData,
    };
  }, [form]);

  return (
    <FormWithPreview previewData={previewChanges}>
      <TextInput label="ra.label.title" source="title" resettable validate={validateRequired} />

      <FileLabel validate={validateRequired}>Logo</FileLabel>
      <S3FileInput
        apiRoot={baseUrl}
        source="logo"
        validate={validateRequired}
        uploadOptions={{
          id: 'logo',
          signingUrl: `${baseUrl}/s3/sign`,
          s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
          multiple: false,
          accept: 'image/*',
        }}
      />

      <FileLabel validate={validateRequired}>Logo Mobile</FileLabel>
      <S3FileInput
        apiRoot={baseUrl}
        source="logoMobile"
        validate={validateRequired}
        uploadOptions={{
          id: 'logoMobile',
          signingUrl: `${baseUrl}/s3/sign`,
          s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
          multiple: false,
          accept: 'image/*',
        }}
      />

      <FileLabel validate={validateRequired}>Logo Mobile App</FileLabel>
      <S3FileInput
        apiRoot={baseUrl}
        source="logoMobileApp"
        validate={validateRequired}
        uploadOptions={{
          id: 'logoMobileApp',
          signingUrl: `${baseUrl}/s3/sign`,
          s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
          multiple: false,
          accept: 'image/*',
        }}
      />

      <TextInput label="ra.label.phone" source="phone" resettable validate={validateRequired} />
      <TextInput label="ra.label.email" source="email" resettable validate={validateRequired} />

      <TextInput label="ra.label.app_store_link" source="links.appStoreLink" resettable />
      <TextInput label="ra.label.google_play_link" source="links.googlePlayLink" resettable />

      <br />

      <SelectInput
        source="flags.categoryStyle"
        choices={[
          { id: 'CAROUSEL', name: 'Carousel' },
          { id: 'GRID', name: 'Grid' },
        ]}
      />
      {
          ColorFieldsList.map((fieldName) => (
            <TextInput
              label={fieldName}
              source={`colors.${fieldName}`}
              resettable
              validate={[validateRequired, colorValidation]}
            />))
        }
    </FormWithPreview>);
};

export default UIThemeForm;
