import React, { useEffect, useMemo, useState } from 'react';
import { FunctionField, useListContext, useListController, useTranslate } from 'react-admin';
import { TableHead, TableRow, TableCell } from '@mui/material';

import notification from '../../../assets/notification.wav';
import OrderedAtField from '../../../components/OrderedAtField';
import PriceField from '../../../components/PriceField';
import StatusLabel from '../../../components/StatusLabel';
import { ORDER_STATUS_NAMES } from '../../../config/statuses.config';
import { ALL_USER_ROLES_NAMES } from '../../../config/user.config';
import ShowActions from '../Actions';
import CommentOrder from '../CommentOrderButton';
import EditOrder from '../EditOrderButton';
import NotificationColumn from '../NotificationColumn';
import PickupTimePicker from '../PickupTimePicker';
import CustomDatagrid from './CustomDatagrid';

// Custom header for today orders page
const DatagridHeader = ({ children }) => {
  const translate = useTranslate();

  return (
    <TableHead>
      <TableRow>
        {React.Children.map(children, (child) => {
          return (
            <TableCell key={child?.props?.label} sx={{ padding: '4px', textAlign: 'left' }}>
              {translate(child?.props?.label?.toString() || '')}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export const ShortOrderId = ({ id }) => {
  return id.slice(-5);
};

export const Supplier = ({ supplier }) => {
  const supplierValues = [supplier?.supplierName, supplier?.branchName].filter(Boolean);

  return supplierValues.join(', ');
};

export const Address = ({ targetAddress: { geo, ...restAddress } }) => {
  const { locality, house, street } = restAddress;
  const addressFields = [locality, street, house];
  const targetAddressValues = addressFields.filter(Boolean);

  return targetAddressValues.join(', ');
};

const DailyId = ({ id, ids, total, page, perPage }) => {
  let ordersBefore = total - page * perPage;

  if (ordersBefore < 0) {
    ordersBefore = 0;
  }
  const orderNumInReverse = ids.length - ids.findIndex((orderId) => orderId === id);

  const dailyId = orderNumInReverse + ordersBefore;

  return <span>{dailyId}</span>;
};

const getIsHighlighted = ({ supplierStatus, updatedAt }) =>
  ![
    ORDER_STATUS_NAMES.CANCELED,
    ORDER_STATUS_NAMES.DELIVERED,
    ORDER_STATUS_NAMES.PAYMENT_FAILED,
    ORDER_STATUS_NAMES.SCHEDULED,
  ].includes(supplierStatus) && Date.now() - new Date(updatedAt) > 40 * 60 * 1000;

const highlightRowStyle = (record) => {
  if (record.supplierStatus === ORDER_STATUS_NAMES.SCHEDULED) {
    return {
      background: '#d8d8d8',
    };
  }

  return {
    background: getIsHighlighted(record) ? '#FFDFDE' : 'none',
  };
};

const notificationAudio = new Audio(notification);

const OrderListDatagrid = ({ listProps = {}, isTodayOrdersPage }) => {
  const { data: rows, total, perPage, page, isLoading } = useListContext();

  const defaultIds = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return rows?.map((row) => row.id);
  }, [rows, isLoading]);

  const listControler = useListController({
    perPage,
  });

  const localRole = localStorage.getItem('role');
  const isSuperAdmin = localRole === ALL_USER_ROLES_NAMES.ADMIN;

  const [ids, setIds] = useState([]);

  useEffect(() => {
    if (rows?.length) {
      setIds(defaultIds);
    }

    const highlightedOrdersInterval = setInterval(() => {
      if (rows?.length) {
        setIds(defaultIds);
      }
    }, 1000 * 60);

    return () => clearInterval(highlightedOrdersInterval);
  }, [rows, defaultIds]);

  useEffect(() => {
    if (!defaultIds?.length) return;

    let orders = JSON.parse(localStorage.getItem('orders'));
    const arrivedOrdersIds = defaultIds.filter(
      (id, idx) => rows[idx].id === id && rows[idx].supplierStatus === ORDER_STATUS_NAMES.NEW,
    );

    if (!arrivedOrdersIds.length) return;

    if (!orders) {
      orders = arrivedOrdersIds.map((id) => ({ id, isRang: false }));
    }

    const orderIds = orders.map((order) => order.id);

    arrivedOrdersIds.reverse().forEach((id) => {
      if (!orderIds?.includes(id)) orders.unshift({ id, isRang: false });
    });

    if (orders.length > 50) {
      orders = orders.slice(0, 50);
    }

    for (let i = orders.length - 1; i >= 0; i--) {
      const order = orders[i];
      if (!order.isRang) {
        notificationAudio.play();
        order.isRang = true;
        break;
      }
    }

    localStorage.setItem('orders', JSON.stringify(orders));
  }, [defaultIds, rows]);

  return (
    <CustomDatagrid
      {...(!isTodayOrdersPage && listControler)}
      bulkActionButtons={false}
      rowStyle={highlightRowStyle}
      ids={ids}
      header={isTodayOrdersPage && <DatagridHeader />}
      cellStyle={isTodayOrdersPage && { padding: '4px', textAlign: 'left' }}
    >
      {!isTodayOrdersPage ? (
        <FunctionField
          label="&#8470;"
          render={({ id }) => {
            return <DailyId id={id} ids={defaultIds} total={total} page={page} perPage={perPage} />;
          }}
        />
      ) : (
        <span />
      )}
      <FunctionField
        style={{
          letterSpacing: '-0.02em',
          color: '#555555',
        }}
        label="ra.label.uppercase.supplier"
        render={Supplier}
      />
      <FunctionField
        style={{
          letterSpacing: '-0.02em',
          color: '#555555',
        }}
        label="ra.label.order_id"
        render={ShortOrderId}
      />
      <FunctionField
        style={{
          letterSpacing: '-0.02em',
          color: '#555555',
        }}
        label="ra.label.uppercase.address"
        render={Address}
      />
      <OrderedAtField label="ra.label.uppercase.ordered_at" />
      <PickupTimePicker label="ra.label.uppercase.pickup_time" />
      <PriceField label="ra.label.uppercase.price" source="totalPrice" parse={(v) => v / 100} />
      <StatusLabel label="ra.label.uppercase.status" />
      <NotificationColumn label="ra.label.uppercase.notification" {...listProps} />
      <FunctionField
        label="ra.label.uppercase.courier"
        render={({ courier, supplierStatus }) => {
          return supplierStatus !== ORDER_STATUS_NAMES.DELIVERED
            ? [courier?.firstName, courier?.lastName].filter(Boolean).join(' ')
            : null;
        }}
      />
      <ShowActions {...listProps} label="ra.label.uppercase.actions" />
      <CommentOrder label="ra.label.uppercase.comm" />
      {isSuperAdmin && <EditOrder label="ra.label.uppercase.edit" />}
    </CustomDatagrid>
  );
};

export default OrderListDatagrid;
