const messages = {
  ra: {
    week_day: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    label: {
      feeThreshold: 'Fee threshold',
      feeAmount: 'Fee amount',
      maxFreeQuantity: 'Max free quantity',
      maxQuantity: 'Max quantity',
      tags: 'Tags',
      payout: 'Payout',
      document_type: 'Document Type',
      bannerType: 'Banner type',
      auth_providers: 'Authentication providers',
      app_store_link: 'App Store link',
      google_play_link: 'Google Play link',
      category_style: 'Category style',
      refund_reason: 'Refund reason',
      refund_amount: 'Refund amount',
      create_refund: 'Issue a refund',
      commission: 'Platform Commission (%)',
      average_preparation_time: 'Average Preparation Time (minutes)',
      externalSupplierId: 'External Supplier ID',
      locale: 'Locale',
      long: 'Long',
      short: 'Short',
      launch_date: 'Launch date',
      amount_currency: 'Amount ( DKK )',
      currency: 'Currency',
      day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      photo: 'Image',
      items: 'Items',
      cvr: 'CVR',
      id: 'ID',
      label: 'Label',
      action: 'Action',
      filters: 'Filters:',
      perks: 'Perks:',
      name: 'Name',
      notes: 'Notes',
      supplier: 'Supplier',
      supplierBranch: 'Supplier Branch',
      country: 'Country',
      area: 'Area',
      locality: 'Locality',
      street: 'Street',
      house: 'House',
      status: 'Status',
      store: 'Store',
      slug: 'Slug',
      referrals: 'Referrals',
      referrer: 'referrer',
      first_name: 'First name',
      last_name: 'Last name',
      phone: 'Phone',
      busy: 'Busy',
      description: 'Description',
      cook_time: 'Cook time',
      available: 'Available',
      available_products: 'Available products',
      price: 'Price',
      weight: 'Weight',
      serving_size: 'Serving size',
      unit: 'Unit',
      highlight: 'Highlight',
      payment_methods: 'Payment methods',
      payment_config: 'Payment config',
      schedule: 'Schedule',
      category: 'Category',
      active: 'Active',
      inactive: 'Inactive',
      is_multiple: 'Is multiple',
      is_required: 'Required',
      option: 'Option',
      maxSelectedOptions: 'Maximum number of selected options',
      is_free_option: 'Is free option',
      address: 'Address',
      order_instructions: 'Order instructions',
      client: 'Client',
      courier: 'Courier',
      service: 'Service',
      fault: 'Fault',
      restaurant: 'Restaurant',
      refundCulprit: 'Refund Culprit',
      payout_policy: 'Courier payout policy',
      payout_setting: 'Courier payout setting',
      options: 'Options',
      ordered_at: 'Ordered at',
      order_status: 'Order status',
      actions: 'Actions',
      terminal: 'Terminal',
      supplier_select: 'Select supplier',
      company_select: 'Select company',
      supplier_branch_select: 'Select supplier branch',
      meta_title: 'Meta title',
      meta_description: 'Meta description',
      allow_under_age: 'Under age allowed',
      self_management: 'Self Management',
      cover_image: 'Cover image',
      card_image: 'Card image',
      hero_image: 'Hero image',
      courier_source: 'Courier source',
      rank: 'Rank',
      price_range: 'Price range',
      type: 'Supplier type',
      delivery_settings: 'Delivery settings',
      delivery_settings_id: 'Delivery settings ID',
      fixed: 'Fixed',
      progressive: 'Progressive',
      calculation_type: 'Calculation type',
      fixed_price: 'Fixed price',
      base_price: 'Base price',
      distances: 'Distances',
      modifier: 'Modifier (%{DKK})',
      delivery_price: 'Delivery price',
      peak_hours: 'Peak hours',
      order_price_ranges: 'Order price modifiers',
      free_delivery: 'Free delivery',
      setting_type: 'Setting type',
      constant_price_modifier: 'Constant price modifier',
      price_per_km: 'øre/km',
      enabled: 'Enabled',
      message: 'Message',
      masked_card: 'Masked CARD',
      rrn: 'RRN',
      sum: 'Amount',
      created_at: 'Created at',
      order_id: 'Order ID',
      contact_name: 'Contact name',
      user: 'User',
      user_name: 'User name',
      password: 'Password',
      role: 'Role',
      branch: 'Branch',
      email: 'Email',
      comment: 'Comment',
      added: 'Added',
      discount: 'Discount',
      data: 'Valid until',
      selected_count: 'Selected %{count} of %{all} Items',
      set_discount_form_err: 'You must choose Discount',
      last_updated: 'Last Updated',
      error: 'Error',
      updated: 'Updated',
      minute: 'minute',
      minutes: 'minutes',
      ago: 'ago',
      just_now: 'just now',
      filter: 'Filter',
      courier_filters: 'Courier Filters',
      estimatedPreparation: 'Estimated preparation time',
      estimated: 'Estimated',
      actual: 'Actual',
      external: 'External',
      current_courier: 'Current courier',
      from: 'From',
      to: 'To',
      supplier_id: 'Supplier ID',
      transport_type: 'Transport Type',
      coefficient_value: 'Coefficient Value',
      company: 'Company',
      contract: 'Contract Number',
      uppercase: {
        supplier: 'SUPPLIER',
        notification: 'NOTES',
        show: 'SHOW',
        comm: 'COMM',
        edit: 'EDIT',
        address: 'ADDRESS',
        ordered_at: 'TIME',
        price: 'PRICE',
        status: 'STATUS',
        actions: 'ACTIONS',
        courier: 'COURIER',
        pickup_time: 'PICKUP TIME',
      },
      notificationEvent: 'Notification event',
      notificationEventId: 'Notification event ID',
      unlimitedOptions: '0 or empty = unlimited',
      unlimited: 'Unlimited',
      returnToOffice: 'Return to Office',
      doNothing: 'Do nothing',
      nextSteps: 'Next steps',
      refusedOrderComment: 'Refused order comment',
      refund: 'Refund money',
      marketing_campaigns: 'Marketing campaigns',
      marketing_campaigns_action_type: 'Campaign action type',
      marketing_campaign_source_type: 'Campaign source type filter',
      marketing_source_filter_type: 'Filter type',
      marketing_action_type: 'Action type',
      launchDate: 'Launch date',
      effectiveDate: 'Effective date',
      run_init: 'Initiate run',
      run_simulate: 'Simulate run',
      validityInHours: 'Validity in hours',
      discounts_sent: 'Discounts sent',
      discounts_used: 'Discounts used',
      discounts_cost: 'Used discounts cost',
      ordersValue: 'Sold goods price',
      supplier_store: 'Store',
      userId: 'User ID',
      deletedAt: 'Deleted at',
      users: 'Users',
      title: 'Title',
      headerBackgroundColor: 'Header Background Color',
      headerShadowColor: 'Header Shadow Color',
      mainBackgroundColor: 'Main Background Color',
      footerBottomBackgroundColor: 'Footer Bottom Background Color',
      footerTopBackgroundColor: 'Footer Top Background Color',
      footerTitleTextColor: 'Footer Title Text Color',
      footerStaticTextColor: 'Footer Static Text Color',
      footerActiveTextColor: 'Footer Active Text Color',
      primaryColor: 'Primary Color',
      image_mobile: 'Mobile image',
      image_desktop: 'Desktop image',
      redirect_url: 'Redirect URL',
      cityVelocity: 'City Velocity',
      bufferTime: 'Buffer Time',
      notification_channel: 'Notification Channel',
      ssn: 'SSN',
      city: 'City',
      postal_code: 'Postal code',
      main_work: 'Main work',
      nationality: 'Nationality',
      note: 'Note',
      bank_clearing_number: 'Bank clearing number',
      bank_account_number: 'Bank account number',
      tax_percent: 'Tax percent',
    },
    tab: {
      financial: 'Financial',
      general: 'General',
      integrations: 'Integrations',
      branding: 'Branding',
      summary: 'Summary',
      variants: 'Variants',
      option_groups: 'Option groups',
      options: 'Options',
      courier: 'Courier',
      suppliers: 'Suppliers',
      invoices: 'Invoices',
      supplier_addresses: 'Supplier addresses',
      goods: 'Goods',
      user_address: 'Address',
      policy: 'Policy',
      refundedOrders: 'Refunded Orders',
      campaign: 'Campaign',
      run: 'Run',
      participants: 'Participants (%{count})',
      statistics: 'Statistics',
    },
    table: {
      column: {
        option_group_name: 'Option group name',
        activate_for_this_good: 'Activate for this good',
        variant_name: 'Variant name',
        variant_weight: 'Variant weight',
        variant_price: 'Variant price',
      },
    },
    action: {
      refund: 'Refund',
      add_option: 'Add option',
      add_option_group: 'Add option group',
      add_variant: 'Add variant',
      add_filter: 'Add filter',
      add_address: 'Add address',
      add_good: 'Add good',
      add_supplier: 'Add supplier',
      agree: 'Agree',
      disagree: 'Disagree',
      manage_product: 'Manage product',
      menu_save: 'Save menu',
      menu_add_new_section: 'Add new section',
      menu_parse_products: 'Parse products',
      menu_manage_products: 'Manage products',
      menu_edit_section_title: 'Edit section title',
      add: 'Add',
      back: 'Back',
      bulk_actions: '1 selected element |||| %{smart_count} selected elements',
      cancel: 'Cancel',
      clear_input_value: 'Clear input value',
      clone: 'Clone',
      confirm: 'Confirm',
      create: 'Create',
      delete: 'Delete',
      edit: 'Edit',
      export: 'Export',
      list: 'List',
      refresh: 'Refresh',
      remove_filter: 'Remove filter',
      remove: 'Remove',
      save: 'Save',
      search: 'Search',
      show: 'Show',
      sort: 'Sort',
      stop: 'Stop',
      resume: 'Resume',
      undo: 'Undo',
      expand: 'Expand',
      close: 'Close',
      export_csv: 'Export as .csv',
      select_time_range: 'Select time range',
      process_transaction: 'Process transaction',
      select_all: 'Select All',
      remove_all: 'Remove All',
      select_section: 'Select Section',
      remove_section: 'Remove Section',
      set_discount: 'Set Discount',
      remove_discount: 'Remove Discount',
      select_discount: 'Select Discount',
      export_monthly_report: 'Export monthly report',
      generate_report: 'Generate report',
      give_bonus: 'Give bonus',
      price_edit: 'Edit price',
      edit_schedule: 'Edit schedule',
      use_schedule: 'Use schedule',
      password_reset: 'Reset password',
    },
    status: {
      button: {
        processing: 'Process',
        confirmed: 'Confirm',
        preparing: 'Preparing',
        prepared: 'Prepared',
        delivering: 'Delivering',
        delivered: 'Delivered',
        accepted: 'Accept',
        canceled: 'Cancel',
      },
      new: 'New',
      accepted: 'Accepted',
      canceled: 'Canceled',
      processing: 'Processing',
      confirmed: 'Confirmed',
      preparing: 'Preparing',
      prepared: 'Prepared',
      delivering: 'Delivering',
      delivered: 'Delivered',
      awaiting_payment: 'Awaiting payment',
      payment_failed: 'Payment failed',
      courier_assign: 'Courier assigned',
      courier_assigned: 'Courier assigned',
      courier_reassign: 'Courier reassigned',
      courier_confirmed: 'Courier confirmed',
      courier_in_restaurant: 'Courier in restaurant',
      in_progress: 'In progress',
      scheduled: 'Scheduled',
      refused: 'Refused',
    },
    boolean: {
      true: 'Yes',
      false: 'No',
    },
    discount: {
      cannotHandleFilter: "Can't handle filter of type %{type}!",
      cannotHandlePerk: "Can't handle perk of type %{type}!",
      paymentFilter: {
        set: 'Set payment type: ',
      },
      sumFilter: {
        set: 'Set minimum amount: ',
      },
      percentPerk: {
        set: 'Set percent amount: ',
      },
      amountPerk: {
        set: 'Set money amount: ',
      },
    },
    page: {
      create: 'Creating %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Something went wrong',
      list: '%{name}',
      loading: 'Loading',
      not_found: 'Not found',
      show: '%{name} #%{id}',
      empty: 'No items found',
    },
    title: {
      refunds: 'Refunds',
      total_refunds: 'Total refunds',
      address_data: 'Address data',
      map: 'Map',
      order_instruction: 'Order instructions',
      instruction: 'Instruction',
      client_data: 'Client data',
      supplier_data: 'Supplier data',
      courier_data: 'Courier data',
      order_items: 'Order items',
      total_price: 'Total price',
      total_discounts: 'Promo',
      order_price: 'Order price',
      delivery_price: 'Delivery price',
      time_data: 'Time data',
      processing_time: 'Processing time',
      preparing_time: 'Preparing time',
      delivery_time: 'Delivery time',
      total_time: 'Total time',
      payment_type: 'Payment type',
      porch: 'Porch',
      porch_code: 'Porch code',
      floor: 'Floor',
      apartment: 'Ap.',
      today: 'Today',
      total: 'Total',
      sum: 'Sum',
      assign_courier: 'Assign courier',
      re_assign_courier: 'Reassign courier',
      not_confirmed_courier: 'Not confirmed by courier. Reassign courier',
      show_order: 'Show order',
      edit_order: 'Edit order',
      reports: 'Reports',
      select_courier: 'Select courier',
      comment_order: 'Add comment to the order',
      add_new_item: 'Add new item',
      select_item: 'Select item',
      status_history: 'Status History',
      fiscal_difference: 'Fiscal Difference',
      scheduled_date: 'Scheduled date',
      block: {
        address: 'Address',
        order_staff_comment: 'Staff comment',
        order_comment: 'Order comment',
        client_data: 'Client',
        supplier_data: 'Supplier',
        courier_data: 'Courier',
        order_items: 'Order items',
        price: 'Price',
        time_data: 'Time',
        payment_type: 'Payment type',
      },
      pickupTime: 'Pickup time',
      service_fees: 'Service fees',
    },
    message: {
      no_number: 'No phone number',
      menu_activate: 'Are you sure want to activate ',
      menu_deactivate: 'Are you sure want to deactivate ',
      about: 'About',
      are_you_sure: 'Are you sure ?',
      user_is_not_referral: 'User was not invited',
      bulk_delete_content:
        'Sure you want to delete %{name}? |||| Sure you want to delete %{smart_count} elements?',
      bulk_delete_title: 'Delete %{name} |||| Delete %{smart_count} %{name}',
      delete_content: 'Sure you want to delete this element?',
      delete_title: 'Delete %{name} #%{id}',
      details: 'Details',
      error: 'A client error occurred and your request could not be completed.',
      invalid_form: 'The form is not valid. Check for errors.',
      loading: 'Loading page.',
      no: 'No',
      not_found: 'Either you typed the wrong URL, or you followed a bad link.',
      yes: 'Yes',
      cancel_order_confirm: 'You are sure you want to cancel this order?',
      yes_cancel_order: 'Yes, cancel order',
      not_cancel_order: 'No, close this window',
      set_time: 'Set preparation time',
      scheduled_at: 'Placed for',
      invalid_login: 'Invalid login or password',
      image_ratio: 'Warning: Banner(image) ratio on storefront is 5:1 for desktop and 16:9 for mobile',
      html_content_limit: 'Warning: Content limit is 50000 characters. Longer content will cause an error.',
    },
    navigation: {
      no_results: 'No results founded.',
      no_more_results: 'Page number %{page} is outside the boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} is outside the boundaries.',
      page_out_from_end: "I can't go after the last page",
      page_out_from_begin: "I can't go before the page 1",
      page_range_info: '%{offsetBegin}-%{offsetEnd} from %{total}',
      page_rows_per_page: 'Rows per page:',
      next: 'Next',
      prev: 'Previous',
      min: 'min',
      distance: 'Distance',
      km: 'km',
      m: 'm',
      money: 'øre',
      menu_management: 'Menu Management',
      marketing: 'Marketing',
      cms: 'CMS',
      user_management: 'User Management',
      delivery_management: 'Delivery Management',
      supplier_management: 'Supplier Management',
      payment_config: 'Payment config',
      i18n: 'Translation',
      company_management: 'Company Management',
      notification_management: 'Notification Management',
      document_management: 'Document Management',
    },
    auth: {
      user_menu: 'Profile',
      username: 'Username',
      password: 'Password',
      sign_in: 'Sign in',
      sign_in_error: 'Authentication failed, please try again',
      logout: 'Log out',
    },
    notification: {
      updated: 'Element was updated |||| %{smart_count} elements was updated',
      created: 'Element was created',
      deleted: 'Element was removed |||| %{smart_count} elements was removed',
      bad_item: 'Bad item',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error: 'Data provider error. Check console for details.',
      canceled: 'Action was canceled',
      logged_out: 'Your session has ended, please reconnect.',
      set_discount: 'Discount is set',
      remove_discount: 'Discount is removed',
      get_discounts_err: 'Error when retrieving discounts',
      no_selected_discount_items: 'You should select at least one item',
      unsaved_changes: 'Unsaved changes!',
    },
    validation: {
      required: 'Required',
      minLength: 'Min length %{min} characters',
      maxLength: 'Max length %{max} characters',
      minValue: 'It must be minimum %{min}',
      maxValue: 'It must be maximum %{max}',
      number: 'Number required',
      email: 'Email not valid',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
    },
  },
  resources: {
    promocode: {
      name: 'Promo Codes',
    },
    reportsDiscount: {
      name: 'Discounts Report',
    },
    navigation: {
      name: 'Navigation Menu',
    },
    notification: {
      name: 'Notification Events',
    },
    'notification-logging': {
      name: 'Notifications Logging',
    },
    document: {
      name: 'Documents',
    },
    'order/today': {
      name: 'Orders',
    },
    'order/history': {
      name: 'Orders History',
    },
    'order/v1/scheduled': {
      name: 'Scheduled Orders',
    },
    supplier: {
      name: 'Suppliers',
    },
    address: {
      name: 'Addresses',
    },
    store: {
      name: 'Stores',
    },
    'store-supplier': {
      name: 'Store Suppliers',
    },
    goods: {
      name: 'Goods',
    },
    variants: {
      name: 'Variants',
    },
    category: {
      name: 'Categories',
    },
    tag: {
      name: 'Tags',
    },
    optionGroup: {
      name: 'Options Groups',
    },
    option: {
      name: 'Options',
    },
    ingredient: {
      name: 'Ingredients',
    },
    user: {
      name: 'Users',
    },
    useraddress: {
      name: 'User addresses',
    },
    'user/deleted': {
      name: 'User Deleting Requests',
    },
    orderitem: {
      name: 'Order items',
    },
    courier: {
      name: 'Couriers',
    },
    transaction: {
      name: 'Transactions',
    },
    menu: {
      name: 'Menu',
    },
    paymentConfig: {
      name: 'Payment Configs',
    },
    posts: {
      fields: { tag: 'Tags' },
    },
    discount: {
      name: 'Discounts',
    },
    deliverySettings: {
      name: 'Delivery Settings',
    },
    policy: {
      name: 'Courier Payout Policy',
    },
    'marketing-campaigns': {
      name: 'Campaigns',
    },
    'marketing-campaign-runs': {
      name: 'Run',
    },
    'ui/locale': {
      name: 'Locales',
    },
    'ui/static': {
      name: 'Static pages',
    },
    'ui/translation': {
      name: 'Translations',
    },
    'ui/theme': {
      name: 'Branding',
    },
    'ui/banner': {
      name: 'Banners',
    },
    'ui/settings': {
      name: 'Settings',
    },
    'ui/html-seo': {
      name: 'HTML-SEO',
    },
    'ui/footer': {
      name: 'Footer',
    },
    company: {
      name: 'Companies',
    },
    'service-fees': {
      name: 'Service Fees',
    },
  },
};

export default messages;
